export const DEFAULT_PAGE_INDEX = 0;
export const DEFAULT_PAGE = 1;
export const DEFAULT_PAGE_SIZE = 100;
export const REPORT_PAGE_SIZE = 300;

export const DEFAULT_PAGINATION = {
  page: DEFAULT_PAGE,
  page_size: DEFAULT_PAGE_SIZE,
};

export enum LocalStorageKeys {
  RefetchReportInterval = 'refresh-report-interval',
  RefetchReportAsinOn = 'refresh-report-asin-on',
  RefetchReportAsinOff = 'refresh-report-asin-off',
  RefetchDashboard = 'refresh-dashboard',
}

export const DefaultRefetchInterval = {
  [LocalStorageKeys.RefetchReportInterval]: 10000,
  [LocalStorageKeys.RefetchReportAsinOn]: 10000,
  [LocalStorageKeys.RefetchReportAsinOff]: 10000,
  [LocalStorageKeys.RefetchDashboard]: 10000,
};
